<template>
    <div>
        <a-card>
            <template slot="title">
                <strong class="mr-4">Master Supplier</strong>
                <!-- <a-range-picker class="ml-2" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
                <!-- <a-button
            type="button"
            class="btn btn-outline-primar10 ml-2"
  10        @click="connecto10('nonanggota'10"
          >
            Tambah Non Anggota
          </a-button> -->
                <a-button-group>
                    <a-button icon="plus-circle" class="text-primary" type="" @click="showModal('create', {})">
                        Tambah
                    </a-button>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>Refresh Data</span>
                        </template>
                        <a-button @click="getAllData(true)">
                            <a-icon type="sync" :spin="loadingRefresh" class="text-success" />
                        </a-button>
                    </a-tooltip>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>Export Data</span>
                        </template>
                        <a-button @click="exportExcel">
                            <a-icon type="file-excel" class="text-success" />
                        </a-button>
                    </a-tooltip>
                    <a-tooltip placement="top">
                        <template slot="title">
                            <span>Print Preview</span>
                        </template>
                        <a-button @click="exportPdf">
                            <a-icon type="printer" class="text-success" />
                        </a-button>
                    </a-tooltip>
                </a-button-group>
            </template>
            <a-table :columns="columns" :dataSource="data" :pagination="{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '20', '30'],
            }" size="small"
      style="margin: -25px;">
                <div slot="filterDropdown" slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }" style="padding: 8px;">
                    <a-input v-ant-ref="(c) => (searchInput = c)" :placeholder="`Search ${column.title}`"
                        :value="selectedKeys[0]" style="width: 188px; margin-bottom: 8px; display: block;" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                            " @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)
                                " />
                    <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px;" @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)
                        ">
                        Search
                    </a-button>
                    <a-button size="small" style="width: 90px;" @click="() => handleReset(clearFilters)">
                        Reset
                    </a-button>
                </div>
                <a-icon slot="filterIcon" slot-scope="filtered" type="search"
                    :style="{ color: filtered ? '#108ee9' : undefined }"></a-icon>
                <template slot="customRender" slot-scope="text, record, index, column">
                    <span v-if="searchText && searchedColumn === column.dataIndex">
                        <template v-for="(fragment, i) in text
                            .toString()
                            .split(
                                new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                            )">
                            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i"
                                class="highlight">{{ fragment }}</mark>
                            <template v-else>{{ fragment }}</template>
                        </template>
                    </span>
                    <template v-else>
                        {{ text }}
                    </template>
                </template>
                <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
                <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
                <!-- <span slot="reorder" slot-scope="text">
            <a-tag :color="text === 1 ? 'green' : 'red'">{{ text === 1 ? 'Reorder' : 'Tidak' }}</a-tag>
          </span> -->
                <span slot="action" slot-scope="text, record">
                    <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
                        <a-icon type="edit"></a-icon>
                    </a>
                    <a-divider type="vertical" />
                    <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
                        <a-icon type="delete"></a-icon>
                    </a>
                    <!-- <a href="javascript:;" class="ant-dropdown-link">
              More actions <a-icon type="down" />
            </a> -->
                </span>
                <span slot="harga" slot-scope="text">
                    {{ formatCurrency(text) }}
                </span>
                <span slot="tgl" slot-scope="text">
                    {{ formatDate(text) }}
                </span>
            </a-table>
        </a-card>
        <a-modal v-model="formvisible" title="Form Supplier" :dialogStyle="{ top: '10px' }">
            <template slot="footer">
                <a-button class="btn btn-outline-danger" @click="handleCancel">Cancel</a-button>
                <a-button class="btn btn-outline-primary" @click="handleSubmit">Submit</a-button>
            </template>
            <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                    <label class="">Nama</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                    <a-input class="" v-model="modalInput.nama"
                        placeholder="Masukan Nama"></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                    <label class="">Alamat</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input class="" v-model="modalInput.alamat" placeholder="Masukan Alamat"></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                    <label class="">No. HP</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input class="" v-model="modalInput.no_hp" placeholder="Masukan No. HP"></a-input>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Alamat',
    dataIndex: 'alamat',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'No. HP',
    dataIndex: 'no_hp',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.no_hp.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      loadingRefresh: false,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterDataKategori: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        nama: '',
        no_hp: '',
        alamat: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-21
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getDataMaster()
  },
  methods: {
    moment,
    async exportPdf() {
      var res = await lou.ngetDocumentPdf('retail/master/supplier?preview=pdf', false, false)
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
    },
    showModal(action, data) {
    //   this.getDataMaster()
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
      } else {
        this.action = 'create'
        this.modalInput = {
          nama: '',
          no_hp: '',
          alamat: '',
        }
        this.editdata = {}
      }
    },
    async getDataMaster() {
      var resmasterDataKategori = await lou.customUrlGet2('master/kategori_supplier')
      if (resmasterDataKategori !== null) {
        this.masterDataKategori = []
        resmasterDataKategori.data.forEach(element => {
          var ret = {
            ...element,
            label: element.keterangan !== '' && element.keterangan !== null ? element.nama + ' - ' + element.keteragangan : element.nama,
          }
          this.masterDataKategori.push(ret)
        })
      } else {
        lou.shownotif('Error!', 'Master Data Kategori failed to fetch from API.', 'error')
      }
    },
    async getAllData(onclick = false) {
      this.loadingRefresh = true
      var response = await lou.customUrlGet2('retail/master/supplier')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      } else {
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('retail/master/supplier/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('retail/master/supplier', fd)
      } else {
        res = await lou.customUrlPost2('retail/master/supplier', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        nama: '',
        no_hp: '',
        alamat: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Supplier.xlsx'
      NProgress.done()
      link.click()
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style scoped>
.highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
}
</style>
